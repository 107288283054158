import { combineReducers } from '@reduxjs/toolkit'

import { horizonPredictApi } from '../apis/horizonPredict'
import progressSlice from './progressSlice'

const rootReducer = combineReducers({
  progress: progressSlice,
  [horizonPredictApi.reducerPath]: horizonPredictApi.reducer,
})

export default rootReducer

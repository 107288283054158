import { horizonPredictApi } from "../../redux/apis/horizonPredict";

const inventoryService = horizonPredictApi.injectEndpoints({
    endpoints: build => ({
        getInventory: build.query({
            providesTags: ['Inventory'],
            query: () => ({
                url: `inventory.fetch`,
                method: 'GET',
            }),
        }),
        getInvCategory: build.query({
            providesTags: ['InvCategory'],
            query: () => ({
                url: `invcategory.fetch`,
                method: 'GET',
            }),
        }),
        createInventory: build.mutation({
            query: ({ body }) => ({
                url: 'inventory.create',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Inventory', 'InvCategory']
        }),
        updateInventory: build.mutation({
            query: ({ body }) => ({
                url: 'inventory.update',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Inventory']
        }),
        batchInventory: build.mutation({
            query: ({ body }) => ({
                url: 'inventory.batch',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Inventory']
        }),
        getItemList: build.query({
            query: () => ({
                url: `itemlist.fetch`,
                method: 'GET',
            }),
        }),
        deleteInventoryById: build.mutation({
            query: (invId) => ({
                url: `inventory.remove/${invId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Inventory']
        }),
        addInItemList: build.mutation({
            query: ({ body }) => ({
                url: 'itemlist.add',
                method: 'POST',
                body
            }),
        }),
    })
});

export const {
    useGetInventoryQuery,
    useGetInvCategoryQuery,
    useGetItemListQuery,
    useCreateInventoryMutation,
    useUpdateInventoryMutation,
    useBatchInventoryMutation,
    useDeleteInventoryByIdMutation,
    useAddInItemListMutation
} = inventoryService;

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export function getInitials(fullName) {
  if (fullName) {
    return fullName
      .split(' ')
      .map((name) => name[0])
      .join('')
  }
}

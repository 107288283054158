import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Button, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'

import { toast } from 'react-toastify'

import { updateProgess } from '../../redux/reducers/progressSlice'
import { useSubmitSupportFormMutation } from '../../services/user'

export default function Support() {
  const [submitSupportForm, { isLoading: isSubmitting }] = useSubmitSupportFormMutation()
  const [typeOfInquiry, setTypeOfInquiry] = useState('Support')
  const [typeOfSupport, setTypeOfSupport] = useState('Help Request')

  const dispatch = useDispatch()

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      let subject = ''
      let message = ''
      if (typeOfInquiry === 'Support') {
        subject = typeOfSupport
        message = e.target.message.value
      }
      if (typeOfInquiry === 'General Question/Comment') {
        subject = 'General Question/Comment'
        message = e.target.generalMessage.value
      }
      let resp = await submitSupportForm({
        body: {
          subject,
          message
        }
      }).unwrap()
      if (resp.success) {
        toast.success(resp.message)
      } else {
        toast.error(resp.message)
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again later.')
    }
  }

  if (isSubmitting) {
    dispatch(updateProgess(true))
  } else {
    dispatch(updateProgess(false))
  }

  return (
    <form className="w-full bg-gray-50 shadow rounded-lg p-8" onSubmit={handleSubmit}>
      <div>
        <div className="text-2xl font-bold">Type of Inquiry</div>
        <RadioGroup
          row
          name="typeOfInquiry"
          defaultValue="Support"
          value={typeOfInquiry}
          onChange={(e) => {
            setTypeOfInquiry(e.target.value)
          }}
          className="mt-5"
        >
          <FormControlLabel
            value="Support"
            control={<Radio color="primary" />}
            label="Support"
          />
          <FormControlLabel
            value="General Question/Comment"
            control={<Radio color="primary" />}
            label="General Question/Comment"
          />
        </RadioGroup>
      </div>
      {typeOfInquiry === 'Support' && (
        <div>
          <div className="mt-5 text-xl font-bold">Type of Support</div>
          <RadioGroup
            row
            name="typeOfSupport"
            defaultValue="Help Request"
            value={typeOfSupport}
            onChange={(e) => {
              setTypeOfSupport(e.target.value)
            }}
            className="mt-3"
          >
            <FormControlLabel
              value="Help Request"
              control={<Radio color="primary" />}
              label="Help Request"
            />
            <FormControlLabel
              value="Bug Report"
              control={<Radio color="primary" />}
              label="Bug Report"
            />
            <FormControlLabel
              value="Feedback on Feature"
              control={<Radio color="primary" />}
              label="Feedback on Feature"
            />
            <FormControlLabel
              value="Request/Suggest Feature"
              control={<Radio color="primary" />}
              label="Request/Suggest Feature"
            />
          </RadioGroup>
          <TextField
            label="Message"
            color="primary"
            fullWidth
            multiline
            rows={5}
            id="message"
            name="message"
            placeholder="Please enter details. Provide as much information as possible."
            variant="outlined"
            focused
            required
            className='mt-3'
          />
        </div>
      )}
      {typeOfInquiry === 'General Question/Comment' && (
        <div>
          <TextField
            label="Message/Question"
            color="primary"
            fullWidth
            multiline
            rows={5}
            id="generalMessage"
            name="generalMessage"
            placeholder="Please enter details. Provide as much information as possible."
            variant="outlined"
            focused
            required
            className='mt-3'
          />
        </div>
      )} 
      <Button
        className="bg-primary w-fit mt-5"
        type="submit"
        variant="contained"
      >
        Submit
      </Button>
      <div className='text-xl mt-5'>Please email us at <a href='mailto:support@horizonpredict.com?subject=Support%20Inquiry' className='text-primary'>support@horizonpredict.com</a> for all other inquiries</div>
    </form>
  )
}

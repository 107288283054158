export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export const getUser = (token) => {
  let user = parseJwt(token)
  user = user ? user : { ...user, exp: 0 }
  return user
}

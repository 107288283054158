import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { ThemeProvider } from '@mui/material'

import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import './App.css'
import ProgressBar from './components/progress-bar/ProgressBar'
import DashboardLayout from './layout/dashboard/Layout'
import MainLayout from './layout/home/MainLayout'
import Home from './pages/home'
import Login from './pages/login'
import Dashboard from './pages/dashboard'
import Inventory from './pages/inventory'
import Products from './pages/products'
import store from './redux/store'
import theme from './theme'
import Contact from './pages/contact'
import ForgotPassword from './pages/forgot-password'
import SmallBusinesses from './pages/solutions/small-businesses'
import StartUps from './pages/solutions/startups'
import Hobbyists from './pages/solutions/hobbyists'
import Individuals from './pages/solutions/individuals'
import SignUp from './pages/sign-up'
import ResetPassword from './pages/reset-password'
import Support from './pages/support'
import TermsOfUse from './pages/terms-of-use'
import PrivacyPolicy from './pages/privacy-policy'

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ProgressBar />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route path="/" element={<Home />} />
              <Route
                path="/solutions/small-businesses"
                element={<SmallBusinesses />}
              />
              <Route path="/solutions/start-ups" element={<StartUps />} />
              <Route path="/solutions/hobbyists" element={<Hobbyists />} />
              <Route path="/solutions/individuals" element={<Individuals />} />
              <Route path="/contact" element={<Contact />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/" element={<DashboardLayout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/inventory" element={<Inventory />} />
              <Route path="/products" element={<Products />} />
              <Route path="/support" element={<Support />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <ToastContainer
          position="top-right"
          theme="colored"
          closeOnClick
          autoClose={1000}
          hideProgressBar={true}
        />
      </ThemeProvider>
    </Provider>
  )
}

export default App

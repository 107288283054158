import { pageContent } from "../../constants/page-content";

export default function PrivacyPolicy() {

  return (
    <div className="min-h-full py-16 mx-auto flex flex-col max-w-7xl px-5">
      <div className="text-2xl font-bold mb-5">Privacy Policy</div>
      {pageContent.privacyPolicy.description.map((item, index) => (
        <div key={index} className='mb-5'> 
          {item}
        </div>
      ))}
      <div className="space-y-5">
        {pageContent.privacyPolicy.paragraphs.map((p, index) => (
          <div key={index} className=''>
            <div className="text-lg font-bold">{p.title}</div>
            <div className='h-[1px] bg-gray-500 mt-1' />
            <div className='mt-1'>{p.text}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'

const mutex = new Mutex()

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  credentials: 'include',
  prepareHeaders: (headers) => getAuthHeaders(headers),
})

const getAuthHeaders = (headers) => {
  headers = headers || new Headers()

  headers.set('X-App-Authorization', process.env.REACT_APP_APP_TOKEN)
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    headers.set('Access-Control-Allow-Credentials', 'true')
    headers.set('Authorization', `Bearer ${accessToken}`)
  }
  return headers
}

const baseQueryWithRefresh = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()

  let result = await baseQuery(args, api, extraOptions)

  if (api.endpoint === 'jwtLogin') {
    return result
  }

  if (result.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      console.log('401 detected: ', result)

      try {
        const refreshResult =
          (await baseQuery(
            {
              credentials: 'include',
              method: 'post',
              url: 'jwt.refresh',
              body: {
                refreshToken: localStorage.getItem('refreshToken')
              }
            },
            api,
            extraOptions
          )) || undefined

        if (refreshResult?.data?.accessToken) {
          const { accessToken, refreshToken } = refreshResult.data

          updateSessionDataInLocalStorage(accessToken, refreshToken)

          // Retry the initial query
          result = await baseQuery(args, api, extraOptions)
        } else {
          forgetAccessToken()
          window.location.href = '/login'
        }
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result
}

const updateSessionDataInLocalStorage = (accessToken, refreshToken) => {
  localStorage.setItem('accessToken', accessToken)
  localStorage.setItem('refreshToken', refreshToken)
}

const forgetAccessToken = () => localStorage.removeItem('accessToken')

export const horizonPredictApi = createApi({
  reducerPath: 'horizonPredictApi',
  baseQuery: baseQueryWithRefresh,
  endpoints: () => ({}),
  tagTypes: ['Inventory'],
})

import React from 'react'
import { Link } from 'react-router-dom'

export default function FooterLayout() {
  return (
    // define #0B374A in color list
    <div className='bg-[#0B374A]'>
        <div className="mx-auto max-w-7xl px-6 py-6 lg:flex lg:items-center lg:justify-between lg:px-8">
            <div className="flex justify-center space-x-6 lg:order-2">
                <a target="_blank" rel="noreferrer" href='https://www.facebook.com/people/HorizonPredict/61560031793483' className="w-7 h-7">
                    <img src='/home/facebook.png' alt='facebook'/>
                </a>
                <a target="_blank" rel="noreferrer" href='https://www.linkedin.com/company/horizonpredict' className="w-7 h-7">
                    <img src='/home/linkedin.png' alt='linkedin' />
                </a>
                <a target="_blank" rel="noreferrer" href='https://www.instagram.com/horizonpredictofficial' className="w-7 h-7">
                    <img src='/home/instagram.png' alt='instagram'/>
                </a>
            </div>
            <div className="mt-8 lg:order-1 lg:mt-0 flex items-center justify-center gap-x-8 text-center">
                <img className="size-12 hidden lg:block" src="/logo-transparent.png" alt="" />
                <div className='flex flex-col lg:flex-row gap-x-8 text-white gap-y-3'>
                    <div className='hidden lg:block lg:order-2'>|</div>
                    <Link to='/terms-of-use' className='no-underline lg:order-3 text-white'>
                        Terms of Use
                    </Link>
                    <div className='hidden lg:block lg:order-4'>|</div>
                    <Link to='/privacy-policy' className='no-underline lg:order-5 text-white'>
                        Privacy Policy
                    </Link>
                    <div className="text-center leading-5 text-white lg:order-1">
                        &copy; 2024 HorizonPredict All Rights Reserved.
                    </div>
                </div>
            </div>
      </div>
    </div>
  )
}

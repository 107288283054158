import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import MenuItem from './MenuItem'

export default function MenuGroup(props) {
  const [open, setOpen] = useState(false)
  let { menu, depthLevel } = props
  depthLevel = depthLevel + 1

  const location = useLocation()

  useEffect(() => {
    if (menu?.children?.some((e) => e?.href === location.pathname)) {
      setOpen(true)
    }
  }, [])

  const handleClickExpand = (open) => {
    setOpen(open)
  }

  return (
    <div className="space-y-1">
      <MenuItem
        menu={menu}
        depthLevel={depthLevel}
        onClick={handleClickExpand}
      />
      {((menu?.children?.length > 0 && location.pathname.includes(menu.href)) ||
        (menu?.children?.length > 0 && open && !menu.href)) && (
        <div className="w-full">
          {menu.children.map((item) => {
            return (
              <MenuGroup menu={item} depthLevel={depthLevel} key={item.name} />
            )
          })}
        </div>
      )}
    </div>
  )
}

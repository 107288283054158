import { horizonPredictApi } from '../../redux/apis/horizonPredict'

const passwordResetService = horizonPredictApi.injectEndpoints({
  endpoints: (build) => ({
    passwordReset: build.mutation({
      query: ({ body }) => ({
        url: 'password.reset',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: build.mutation({
      query: ({ body }) => ({
        url: 'reset.password',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { usePasswordResetMutation, useResetPasswordMutation } =
  passwordResetService

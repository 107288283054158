import * as _ from 'lodash'

import { horizonPredictApi } from '../../redux/apis/horizonPredict'

const measurementService = horizonPredictApi.injectEndpoints({
  endpoints: (build) => ({
    getMeasurement: build.query({
      query: (userId) => ({
        url: 'measurement.fetch',
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetMeasurementQuery } = measurementService

export const getMeasureName = (measurement, measureId) => {
  if (!measureId) return ''
  return _.find(_.flatten(_.values(measurement)), { MeasureID: measureId })
    .MeasureName
}

export const getUnit = (measurement, measureId) => {
  return _.find(_.flatten(_.values(measurement)), { MeasureID: measureId })
    .MeasureName
}

export const getDensity = (itemList, itemName) => {
  return _.find(itemList, { ItemName: itemName }).ItemDen
}

export const getCalories = (itemList, itemName) => {
  return _.find(itemList, { ItemName: itemName }).ItemCal
}

const similarMeasureType = {
  Length: ['Length', 'Area'],
  Area: ['Length', 'Area'],
  Weight: ['Weight', 'Volume'],
  Volume: ['Weight', 'Volume'],
  Temperature: ['Temperature'],
  'Individual Count': ['Individual Count'],
}

export const getMeasurement = (measurement, srcMeasureId) => {
  if (!srcMeasureId) return measurement
  const srcMeasureType = _.find(_.flatten(_.values(measurement)), {
    MeasureID: srcMeasureId,
  }).MeasureType
  let newMeasurement = {}
  similarMeasureType[srcMeasureType].forEach((item) => {
    newMeasurement[item] = measurement[item]
  })
  return newMeasurement
}

// Conversion factors
const weightConversion = {
  mg: 1 / 1000,
  g: 1,
  kg: 1000,
  ton: 1000000,
  lb: 453.592,
  oz: 28.3495,
}

const volumeConversion = {
  ml: 1,
  l: 1000,
  gal: 3785.41,
  qt: 946.353,
  pt: 473.176,
  cup: 240,
}

// Convert weight to grams
const weightToGrams = (weight, unit) => {
  return weight * weightConversion[unit]
}

// Convert volume to milliliters
const volumeToMilliliters = (volume, unit) => {
  return volume * volumeConversion[unit]
}

// Convert grams to target weight unit
const gramsToWeight = (grams, unit) => {
  return grams / weightConversion[unit]
}

// Convert milliliters to target volume unit
const millilitersToVolume = (milliliters, unit) => {
  return milliliters / volumeConversion[unit]
}

// Main conversion function
export const convertMeasurement = (
  sourceValue,
  sourceUnit,
  targetUnit,
  density
) => {
  let result

  // Weight to Weight conversion
  if (
    Object.keys(weightConversion).includes(sourceUnit) &&
    Object.keys(weightConversion).includes(targetUnit)
  ) {
    const grams = weightToGrams(sourceValue, sourceUnit)
    result = gramsToWeight(grams, targetUnit)
  }
  // Volume to Volume conversion
  else if (
    Object.keys(volumeConversion).includes(sourceUnit) &&
    Object.keys(volumeConversion).includes(targetUnit)
  ) {
    const milliliters = volumeToMilliliters(sourceValue, sourceUnit)
    result = millilitersToVolume(milliliters, targetUnit)
  }
  // Weight to Volume conversion
  else if (
    Object.keys(weightConversion).includes(sourceUnit) &&
    Object.keys(volumeConversion).includes(targetUnit)
  ) {
    const grams = weightToGrams(sourceValue, sourceUnit)
    const milliliters = grams / density // Density must be in g/ml
    result = millilitersToVolume(milliliters, targetUnit)
  }
  // Volume to Weight conversion
  else if (
    Object.keys(volumeConversion).includes(sourceUnit) &&
    Object.keys(weightConversion).includes(targetUnit)
  ) {
    const milliliters = volumeToMilliliters(sourceValue, sourceUnit)
    const grams = milliliters * density // Density must be in g/ml
    result = gramsToWeight(grams, targetUnit)
  } else {
    throw new Error('Invalid conversion types')
  }

  return result
}

/*
  // Example usage:
  const sourceValue = 100; // Value to convert
  const sourceUnit = 'g'; // Source unit from the object
  const targetUnit = 'kg'; // Target unit from the object
  const density = 0.92; // Density in g/cm³ (e.g., for olive oil)
  
  const convertedValue = convertMeasurement(sourceValue, sourceUnit, targetUnit, density);
  console.log(`Converted value: ${convertedValue} ${targetUnit}`);
*/

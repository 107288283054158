import { createSlice } from '@reduxjs/toolkit'

export const progressSlice = createSlice({
  name: 'progress',
  initialState: {
    isLoading: false,
  },
  reducers: {
    updateProgess: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const { updateProgess } = progressSlice.actions

export default progressSlice.reducer

import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button, TextField, Typography } from '@mui/material'
import { toast } from 'react-toastify'

import { useResetPasswordMutation } from '../../services/password-reset'

export default function ResetPassword() {
  const [searchParams] = useSearchParams()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState(false)
  const [resetPassword, { isPasswordReseting }] = useResetPasswordMutation()

  const navigate = useNavigate()

  const userId = searchParams.get('userId')
  const token = searchParams.get('token')

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value)
    setError(e.target.value !== password)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    localStorage.setItem('accessToken', token)

    try {
      let resp = await resetPassword({
        body: {
          userId,
          password,
        },
      }).unwrap()
      if (resp.success) {
        toast.success('Password changed successfully.')
        navigate('/login')
      } else {
        toast.error(resp.message)
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again later.')
    }
  }

  return (
    <div className="min-h-full flex flex-col justify-center items-center h-screen sm:px-6 lg:px-8">
      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
          <div className="flex flex-row justify-center items-center w-full pb-10 ">
            <img
              src="logo-transparent.png"
              alt="Logo"
              className="h-16 w-auto"
            />
          </div>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <TextField
              type="password"
              label="Password"
              color="primary"
              size="small"
              fullWidth
              id="Password"
              name="Password"
              variant="outlined"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              type="password"
              label="Confirm Password"
              color="primary"
              size="small"
              fullWidth
              id="Confirm_Password"
              name="Confirm_Password"
              variant="outlined"
              required
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={error}
              helperText={error ? 'Passwords do not match' : ''}
            />
            <div>
              <Button
                className="bg-primary hover:bg-primary w-full"
                type="submit"
                variant="contained"
              >
                <Typography className="text-sm font-medium text-white p-1">
                  Continue
                </Typography>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
